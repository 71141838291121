<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-multiple-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="grupoLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="10" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="grupoLocal.grupo"
                    :error-messages="compGrupoErrors"
                    label="Grupo"
                    required
                    outlined
                    @input="$v.grupoLocal.grupo.$touch()"
                    @blur="$v.grupoLocal.grupo.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="grupoLocal.descricao"
                    :error-messages="compDescricaoErrors"
                    label="Descrição"
                    required
                    outlined
                    @input="$v.grupoLocal.descricao.$touch()"
                    @blur="$v.grupoLocal.descricao.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSubmit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar()"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

const { mapState, mapActions } = createNamespacedHelpers("adminGrupos");

export default {
  mixins: [validationMixin],
  validations: {
    grupoLocal: {
      grupo: { required },
      descricao: { required },
    },
  },

  data: () => ({
    tituloForm: "",
    grupoLocal: {},
  }),
  computed: {
    ...mapState(["staGrupoSelecionado"]),

    compGrupoErrors() {
      const errors = [];
      if (!this.$v.grupoLocal.grupo.$dirty) return errors;
      !this.$v.grupoLocal.grupo.required && errors.push("Nome requerida.");
      return errors;
    },
    compDescricaoErrors() {
      const errors = [];
      if (!this.$v.grupoLocal.descricao.$dirty) return errors;
      !this.$v.grupoLocal.descricao.required &&
        errors.push("Descricão requerida");
      return errors;
    },
  },
  watch: {
    staGrupoSelecionado(grupoNovo) {
      this.metSincronizar(grupoNovo);
    },
  },
  created() {
    this.metSincronizar(this.staGrupoSelecionado);
    this.staGrupoSelecionado
      ? (this.tituloForm = "Editar Grupo")
      : (this.tituloForm = "Novo Grupo");
  },
  methods: {
    ...mapActions(["actCriarGrupo", "actEditarGrupo"]),

    async metSalvarGrupo() {
      this.grupoLocal.grupo = this.grupoLocal.grupo.trim();

      switch (this.staGrupoSelecionado) {
        case undefined:
          this.actCriarGrupo({ grupo: this.grupoLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Grupo Criado!",
          });
          break;
        default:
          await this.actEditarGrupo({ grupo: this.grupoLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Grupo Salvo!",
          });
      }
    },

    metSincronizar(novoGrupo) {
      this.grupoLocal = Object.assign({}, novoGrupo || { id: null });
    },
    metSubmit() {
      this.$v.$touch();
      this.metSalvarGrupo();
    },
    metClear() {
      this.grupoLocal.grupo = this.staGrupoSelecionado.grupo;
      this.grupoLocal.descricao = this.staGrupoSelecionado.descricao;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
